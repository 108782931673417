import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import useWindowDimensions from "../../hooks/useWindowDimensions";

export function ChatPage() {

    const history = useHistory();
    const { height, width } = useWindowDimensions();

    useEffect(() => {
        initChat();
    }, [])

    function initChat() {
        const search = history.location.search;
        const params = new URLSearchParams(search);
        let name = params.get('nome');
        let email = params.get('email');
        name = '' + name;
        email = '' + email;


        // var _tn: any = _tn || [];
        // _tn.push(['account', 'fa88c09a06ee5ff68ee56694a52ed495']);
        // _tn.push(['action', 'track-view']);
        // _tn.push(['_setAction', 'track-view']);

        // // COMANDOS EXTRAS VÃO AQUI
        // _tn.push(['_setName', name]);
        // _tn.push(['_setEmail', email]);

        // _tn.push(['_setChatMode', 'popup']);
        // _tn.push(['_setCallMode', '1']);

        // document.write(decodeURI("%3Cspan id='tolvnow'%3E%3C/span%3E"));
        // var tss = document.createElement('script');
        // tss.id = "testeBot"
        // tss.type = 'text/javascript';
        // tss.async = true;
        // tss.src = 'https://tracker.tolvnow.com/js/tn.js';
        // var s = document.getElementsByTagName('script')[0];
        // s.parentNode!.insertBefore(tss, s);

    }

    return (
        <div id="teste" style={{ width: '100vw', height: '100vh' }}>

        </div>
    )
}

