import styled from "styled-components";

export const Container = styled.section`

    .subcontainer{
        max-width: 720px;
        margin: 0 auto;
        padding: 1rem;
        display: flex;
        gap: 1rem;
        margin-bottom: 40px;
        
        .box1{
           
            width: 100%;

            .box1content{
                display: flex;
                justify-content: space-between;
                .box1content-text{
                    h2{
                        color: var(--blue-350);
                        font-size: 2rem;
                      
                    }
                    p{
                        margin-top:15px;
                        color: var(--blue-300);
                    }
                    
                }
                .box1content-img{
                    height: 70px;
                    width: 70px;
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-image: url('/assets/stars.png');
                }
            }

            .loanValue{
                margin-top: 50px;
                width: 100%;
                height: 200px;
                background: var(--blue-50);
                border-radius: 35px;
                display: flex;
                flex-direction:column;
                justify-content:center;
                align-items:center;

                span{
                    font-weight:700;
                    color: var(--blue-350);
                    font-size:2.5rem;
                }
                p{
                    line-height: 0.2;
                    color: var(--blue-300); 
                }
            }

            .box1-infoText{
                font-weight: 700;
                text-align:center;
                color: var(--blue-350);
                margin-top: 10px;
                margin-bottom: 40px;
                text-transform:uppercase;
            }
            .imgWrapper{
                width: 50%;
                display: flex;
                justify-content:center;
                width: 100%;
                height: 250px;
                margin-bottom: 40px;
                .img{   
                    background-image: url(/assets/monefy-03.webp);
                    max-height: 250px;
                    width: 100%;
                    height: 100%;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                }
            }
            
            .btn{
                width: 70%;
                align-self:center;
                
            }
        }
    }


   

`