import styled from "styled-components";

export const Container = styled.section`

    .subcontainer{
        max-width: 720px;
        margin: 0 auto;
        padding: 1rem;
        display: flex;
        gap: 1rem;

        .box1{
            width: 100%;
            display: flex;
            flex-direction:column;
            

            .box1content{
                display: flex;
                margin-bottom: 50px;
                gap: 40px;
                justify-content: space-between;
                
                .infoText{
                    
                    h2{
                        color: var(--blue-350);
                        font-size: 2.5rem
                    }
                    p{
                        text-align: justify;
                        color: var(--blue-300);
                    }
                }
                .box1content-img{
                    height: 70px;
                    width: 70px;
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-image: url('/assets/stars.png');      
                }
            }
            div{
                .btn{
                    width: 50%;
                }
            }
            .btnDownloadAppWrapper{
                width: 100%;
                display: flex;
                justify-content: center;
                margin-bottom: 40px;
            }
        }
        
    }

    @media (max-width: 850px){

        .subcontainer{
            .box1{
                .starWrapper{
                    display: none;
                }
            }
        }
    }
`