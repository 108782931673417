import { BackgroundSquares } from '../../components/BackgroundSquares/BackgroundSquares'
import { BtnDownloadApp } from '../../components/BtnDownloadApp/BtnDownloadApp'
import { CustomButton } from '../../components/CustomButton/CustomButton'
import { Header } from '../../components/Header/Header'
import { StarsImg } from '../../components/StarsImg/StarsImg'
import { Subheader } from '../../components/Subheader/Subheader'
import { Container } from './styles'

export function SenhaAlterada() {

    function onSubmit() {

        window.location.href = 'http://www.monefy.com.br';
    }

    return (
        <Container>
            <Header />
            <BackgroundSquares />
            <Subheader hiddenGoBack />
            <div className='subcontainer'>
                <div className='box1'>
                    <div className='box1content'>
                        <div className='infoText'>
                            <h2>Senha alterada com sucesso!</h2>
                            <p>Para solicitar seu empréstimo basta baixar nosso aplicativo e entrar com seu CPF e senha.</p>
                        </div>
                        <div className='starWrapper'>
                            <StarsImg />
                        </div>
                    </div>
                    <div className='btnDownloadAppWrapper'>
                        <BtnDownloadApp />
                    </div>
                    <div>
                        <CustomButton onclick={onSubmit} classname='btn' content={'Voltar à tela inicial'}></CustomButton>
                    </div>
                </div>

            </div>
        </Container>
    )
}