import styled from "styled-components";

const Btn = styled.div`
       background-size: contain;
        background-image: url(/assets/googlePlayImg.png);
        width: 280px;
        height: 85px;

        background-position: center;
        background-repeat: no-repeat;
    a{
       width:100%;  
       height:100%;       
       display:block;   
    }

    @media (max-width:850px){
        width: 210px;
        height: 55px;

    }
    
`
export function BtnDownloadApp() {

    return (
        <Btn>
            <a href="https://play.google.com/store/apps/details?id=com.antecipeapp"></a>
        </Btn>
    )
}