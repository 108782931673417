import styled from "styled-components";

export const Container = styled.section`

    .subcontainer{
        max-width: 720px;
        margin: 0 auto;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        .box1{
            width: 100%;

            .box1contentWrapper{
 
                display: flex;
                justify-content: space-between;
                
                .box1contentWrapper-text{
                    h2{
                        color: var(--blue-350);
                        font-size: 2.5rem;
                    }
                    p{
                        margin-top: 20px;
                        color: var(--blue-300);
                        font-size: 1.125rem;
                    }
                }

                .box1contentWrapper-img{
                    height: 70px;
                    width: 70px;
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-image: url('/assets/stars.png');
                }
            }
        }

        .box2{
            width: 100%;
            padding-top: 2rem;
            display: flex;
            justify-content: center;
            form{
                width: 100%;
                display: flex;
                flex-direction: column;
                //max-width: 400px;
                input + label{
                    margin-top: 20px;
                }
                input + .formSimulacao-checkbox{ 
                    margin-top: 40px;
                }

                .formSimulacao-checkbox{
                    
                    & + .formSimulacao-checkbox{
                        margin-top:20px;
                    }
                    span{
                        color: var(--blue-300);
                        margin-left: 20px;
                    }
                    input{
                        height: 1rem;
                        width: 1rem;
                        position: relative;
                        top: 2px;
                    }
                }
                
                .formSimulacao-btn{
                    margin-top:40px;

                    .btn{
                        width: 50%;
                        align-self: center;  
                    }
                }
            }
        }
    }

    @media (max-width: 1024px){
      
        .subcontainer{
            .box1{
                .box1contentWrapper-img{
                    display: none;
                }
            }
        }
    }   
`