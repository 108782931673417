import styled from "styled-components";

export const Container = styled.section`
    main{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top:50px;
        padding: 0 10px;
        h2{
            color: var(--blue-350);
            font-size: 2rem;
        }
        h3{
            margin-top:15px;
            color: var(--blue-300);
        }
    }

`