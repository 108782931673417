import { FormEvent } from 'react';
import { CustomBtn } from './styles'

interface ButtonProps {
    content: string;
    classname?: string;
    onclick?: () => void;
    alternativeGradient?: boolean;
}

export function CustomButton({ content, onclick, classname = '', alternativeGradient = false }: ButtonProps) {

    const color = !alternativeGradient ? 'var(--linearGradientColor2)' : 'var(--linearGradientColor)';

    function handleExecFunc(e: FormEvent) {

        if (onclick) {
            e.preventDefault();
            onclick();
        }
    }

    return (
        <CustomBtn color={color} >
            <button type="submit" className={`${classname} btn1 `} onClick={handleExecFunc}>{content}</button>
            <button className={`${classname} btn2`} />
        </CustomBtn>
    )
}