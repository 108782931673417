import { toast } from "react-toastify";


export function validaInputCpf(cpf: string) {
    const cfpParaValidacao = cpf.split(/\.|\-|\_/).join('');

    if (cfpParaValidacao.length < 11) {
        toast.error('O cpf tem que ter 11 digitos');
        return false
    }

    return true
}


export function validarTelefone(telefone: string) {
    const telefoneValidacao = telefone.split(/\.|\-|\_|\(|\)/).join('');
    if (telefoneValidacao.length < 11) {
        toast.error('Digite o telefone completo');
        return false
    }
    return true
}

export function validarSenha(senha: string, senhaConfirmar: string) {

    if (senha.length < 6 || senha.length > 10) {
        toast.error('a senha deve ter entre 6 e 10 dígito ');
        return false
    }

    if (senha !== senhaConfirmar) {
        toast.error('as senhas não coincidem');
        return false
    }

    return true
}