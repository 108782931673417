import { BackgroundSquares } from '../../components/BackgroundSquares/BackgroundSquares'
import { BtnDownloadApp } from '../../components/BtnDownloadApp/BtnDownloadApp'
import { CustomButton } from '../../components/CustomButton/CustomButton'
import { Header } from '../../components/Header/Header'
import { StarsImg } from '../../components/StarsImg/StarsImg'
import { Subheader } from '../../components/Subheader/Subheader'
import { useUser } from '../../hooks/useUser'
import { Container } from './styles'


export function Antecipe() {

    const { nome } = useUser();

    function onSubmit() {
        window.location.href = 'http://www.monefy.com.br';
    }

    return (
        <Container>
            <Header />
            <BackgroundSquares />
            <Subheader hiddenGoBack={true} />
            <div className='subcontainer'>
                <div className='box1'>
                    <div className='box1content'>
                        <div className='infoText'>
                            <h2>{nome}</h2>
                            <p>A Antecipe Fintech agora é Monefy, por isso você já é nosso cliente. Baixe o aplicativo da Monefy, entre com seu CPF e sua senha cadastrada na Antecipe Fintech.</p>
                            <br />
                            <p>Caso não se lembre de sua senha, após baixar o aplicativo, vá em "esqueceu sua senha".</p>
                        </div>
                        <div className='starWrapper'>
                            <StarsImg />
                        </div>

                    </div>
                    <div className='btnDownloadAppWrapper'>
                        <BtnDownloadApp />
                    </div>

                    <div>
                        <CustomButton onclick={onSubmit} classname='btn' content={'Voltar à tela inicial'}></CustomButton>
                    </div>
                </div>

            </div>
        </Container>
    )
}