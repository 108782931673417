import { BtnGoBack } from '../BtnGoBack/BtnGoBack'
import { Location } from '../Location/Location'
import styled from 'styled-components'

export const Container = styled.div` 

    display: flex;
    justify-content: space-between;
    
    padding: 1rem;
   // max-width: 1500px;
    max-width: 720px;
    margin: 0 auto;
`
interface SubheaderProps {
    hiddenGoBack?: boolean
    hiddenLocation?: boolean
}

export function Subheader({ hiddenGoBack = false, hiddenLocation = false }: SubheaderProps) {
    return (
        <Container>

            <div className='right'>
                {/* {!hiddenGoBack ? <BtnGoBack /> : ''} */}
            </div>
            <div className='left'>
                {!hiddenLocation ? <Location text={"Simulador"} /> : ''}
            </div>

        </Container >
    )
}