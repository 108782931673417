import { FormEvent, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { BackgroundSquares } from '../../components/BackgroundSquares/BackgroundSquares'
import { CustomButton } from '../../components/CustomButton/CustomButton'
import { Header } from '../../components/Header/Header'
import { Loading } from '../../components/Loading/Loading'
import { Subheader } from '../../components/Subheader/Subheader'
import { validarSenha } from '../../helpers/validadores'
import { useUser } from '../../hooks/useUser'
import { CustomLabel } from '../../styles/CommonStyles/CustomLabelForInput'
import { Container } from './styles'


export function AlterarSenha() {

    const { senha, senhaConfirmar, handleSetSenha, handleSetSenhaConfirmar, postAlterarSenhaAlterar } = useUser();
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    useEffect(() => {
        setLoading(false);
    }, []);

    async function handleSubmit(e: FormEvent) {
        e.preventDefault();
        const valida = validarSenha(senha, senhaConfirmar);

        const search = history.location.search;
        const params = new URLSearchParams(search);
        const token = params.get('token');

        if (!token) {
            toast.error('erro ao alterar senha');
            return
        }
        if (valida) {
            setLoading(true);
            const res = await postAlterarSenhaAlterar(token!, senha, senhaConfirmar);

            if (res !== undefined) {
                setLoading(false);
            }
        }
    }

    return (
        <Container>
            <Loading isLoading={loading} />
            <Header />
            <BackgroundSquares />
            <Subheader hiddenGoBack />
            <div className='subcontainer'>
                <div className='box1'>
                    <form onSubmit={handleSubmit}>
                        <label className='form-info'>Redefina uma senha para continuar</label>
                        <CustomLabel>Senha</CustomLabel>
                        <input className='customInput' onChange={(e) => handleSetSenha(e.target.value)} type="password" required name='senha' />
                        <CustomLabel>Confirmação da senha</CustomLabel>
                        <input className='customInput' onChange={(e) => handleSetSenhaConfirmar(e.target.value)} type="password" required name='confirmarSenha' />
                        <div>
                            <CustomButton classname='btn' content={'continuar'}></CustomButton>
                        </div>
                    </form>
                </div>
            </div>
        </Container>

    )
}

