import styled from "styled-components";

export const Container = styled.section`

    .subcontainer{
        max-width: 720px;
        margin: 0 auto;
        padding: 1rem;
        display: flex;
    
        .box1{
            width: 100%;
            display: flex;
            flex-direction:column;

            .box1content{
                display: flex;
                justify-content: space-between;
                margin-bottom: 50px;
                gap: 40px;
                .infoText{
                    h2{
                        color: var(--blue-350);
                        font-size: 2.5rem
                    }
                    p{
                        color: var(--blue-300);
                        text-align: justify;
                    }
                }
            }
            div{
                .btn{
                    width: 50%;
                }
            }
            .btnDownloadAppWrapper{
                width: 100%;
                display: flex;
                justify-content: center;
                margin-bottom: 40px;
            }
        }
    }
`