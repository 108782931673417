import { createContext, ReactNode, useContext } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { postAlterarSenha, postConsultarBacem, postConsultarSpc, postCpf, postRegistrarSenha, postRegistrarSimulacao, registrarCnhPost } from "../services/services";
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

export interface ISimulacaoDados {
    id: number;
    name: string;
    foto: null | string;
    email: string;
    api_token: string;
    celular: string;
    block: string;
    cnh: boolean;
    documento: string;
    cliente: IClienteDados
}

interface IClienteDados {
    id: number;
    user_id: number;
    banco_id: number;
    nome: string;
    email: string;
    cpf: string;
    telefone_fixo: string;
    telefone_celular: string;
    rg: string;
    uf_rg: string;
    cep: string;
    numero: string;
    rua: string;
    bairro: string;
    cidade: string;
    uf: string;
    complemento: string;
    agencia: string;
    conta: string;
    conta_dig: string;
    tipo_conta: string;
    juros: string;
    juros_iof_taxa: string;
    valor_aprovado: string;
    valor_utilizado: string;
    tipo: string;
    status: string;
    status_antigo: string;
    taxa_proxima_fase: string;
    valor_maximo_proxima_fase: string;
}


export interface IRespostaSPC {
    id: number;
    status: string;
}

const firebaseConfig = {
    apiKey: "AIzaSyA-l0M73I4INTIreXW6fo2WXz3yTMiIUuk",
    authDomain: "monefy-3f1f4.firebaseapp.com",
    projectId: "monefy-3f1f4",
    storageBucket: "monefy-3f1f4.appspot.com",
    messagingSenderId: "601807927566",
    appId: "1:601807927566:web:314309057d9f22ff2d7f5f",
    measurementId: "G-9WRMNHXD29"
};

interface UserContextDataProps {
    children: ReactNode
}

interface UserContextData {
    handleSetCpf: (cpf: string) => void;
    postCpfHome: (cpf: string) => void;
    handleSetEmail: (email: string) => void;
    handleSetTelefone: (telefone: string) => void;
    postRegistrarSimulacaoSimulacao: (cpf: string, email: string, telefone: string, aceitaLead: boolean) => void;
    handleSetSenha: (senha: string) => void;
    handleSetSenhaConfirmar: (senha: string) => void;
    postCriarSenhaSenha: (token: string, senha: string, senhaConfirmar: string, nome: string, email: string) => void;
    postConsultarSpcCnh: (token: string, temCnh: boolean) => void;
    postAlterarSenhaAlterar: (token: string, senha: string, senhaConfirmar: string) => void;
    clearAllData: () => void;
    handleSetChkCNH: () => void;
    handleSetAceitaLead: () => void;
    postRegistrarCnh: (temCnh: boolean, token: string) => void;
    submitEventAnalitycs: (name: string) => void;
    aceitaLead: boolean;
    chkCNH: boolean;
    cpf: string;
    nome: string;
    email: string;
    telefone: string;
    valorEmprestimo: string;
    senhaConfirmar: string;
    senha: string;
    token: string;
}

interface postCpfHomeSuccess {
    data: {
        success: boolean;
    };
    status: number;
}
interface postConsultarBacemSuccess {
    status: number;
    data: {
        nome?: string;
        error?: string;
        status?: string;
    }
}
interface postRegistrarSenhaSuccess {
    status: number;
}
interface postCpfHomeErro {
    response: {
        data: {
            errors?: {
                cpf?: string[];
            };
            message?: string;
            email?: string;
            nome?: string;
            success?: boolean;
            tipo?: string;
        };
        status: number;
        statusText: string;
    }
}

interface postRegistrarSimulacaoSuccess {
    data: {
        api_token: string;
        cliente: {
            valor_maximo_proxima_fase: string;
        }
        name: string;
    };
    status: number;
}

interface postConsultarSpcSuccess {
    data: {
        valor_maximo_proxima_fase?: string;
        error?: string;
        status?: string;
    }
}

interface postAlterarSenhaSuccess {
    status: number;
}
export function UserContextData({ children }: UserContextDataProps) {

    const history = useHistory();
    const [cpf, setCpf] = useState('');
    const [nome, setNome] = useState('');
    const [token, setToken] = useState('');
    const [email, setEmail] = useState('');
    const [telefone, setTelefone] = useState('');
    const [valorEmprestimo, setValorEmprestimo] = useState('');
    const [senha, setSenha] = useState('');
    const [senhaConfirmar, setSenhaConfirmar] = useState('');
    const [chkCNH, setChkCNH] = useState(false);
    const [aceitaLead, setAceitaLead] = useState(false);
    const [fluxo, setFluxo] = useState('comum');


    //analitycs
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);

    //funcao de enviar eventos
    async function submitEventAnalitycs(name: string) {
        await logEvent(analytics, name);
    }
    function handleSetAceitaLead() {
        setAceitaLead(!aceitaLead);
    }
    function handleSetChkCNH() {
        setChkCNH(!chkCNH);
    }
    function handleSetCpf(cpf: string) {
        setCpf(cpf);
    }
    function handleSetNome(nome: string) {
        setNome(nome);
    }
    function handleSetToken(token: string) {
        setToken(token);
    }
    function handleSetEmail(email: string) {
        setEmail(email);
    }
    function handleSetTelefone(telefone: string) {
        setTelefone(telefone);
    }
    function handleSetValorEmprestimo(valor: string) {
        setValorEmprestimo(valor);
    }
    function handleSetSenha(senha: string) {
        setSenha(senha);
    }
    function handleSetSenhaConfirmar(senha: string) {
        setSenhaConfirmar(senha);
    }
    function clearAllData() {
        handleSetCpf('');
        handleSetNome('');
        handleSetToken('');
        handleSetEmail('');
        handleSetTelefone('');
        handleSetValorEmprestimo('');
        handleSetSenha('');
        handleSetSenhaConfirmar('');
    }
    async function postCpfHome(cpf: string) {

        try {
            const res: postCpfHomeSuccess = await postCpf(cpf);

            if (res.status === 200) {
                history.push('/simulacao');
            }

        } catch (err: any) {
            const erro: postCpfHomeErro = err;

            if (erro.response.status === 400) {

                if (erro.response.data.tipo && (erro.response.data.tipo === 'Monefy' || erro.response.data.tipo === 'Monefy Web')) {
                    const nomeErr = erro.response.data.nome;

                    handleSetNome(nomeErr!);
                    history.push('/download');
                }
                else if (erro.response.data.tipo) {
                    const nomeErr = erro.response.data.nome;

                    handleSetNome(nomeErr!);
                    history.push('/antecipe');
                }

            } else {
                toast.error('cpf inválido');
                return err
            }
        }
    }

    async function postRegistrarSimulacaoSimulacao(cpf: string, email: string, telefone: string, acceptLead: boolean) {
        //const telefoneValidado = telefone.split(/\.-_|/).join('');

        try {

            const res = await postRegistrarSimulacao(cpf, email, telefone, acceptLead);
            const cliente: ISimulacaoDados = res.data;

            if (cliente.cliente && cliente.cliente.status === "Reprovado Boa Vista") {
                return history.push("/reprovado");
            }

            handleSetToken(res.data.api_token);
            handleSetNome(res.data.name);
            await postConsultarBacemSimulacao(res.data.api_token);

        } catch (err: any) {

            if (err.response.data.errors) {
                const dataErrors = err.response.data.errors;

                if (dataErrors.email) {
                    toast.error(dataErrors.email[0]);
                    throw new Error(dataErrors.message);
                }
                if (dataErrors.celular) {
                    toast.error(dataErrors.celular[0]);
                    throw new Error(dataErrors.message);
                }
            }
            history.push("/simulacao/erro");
            throw new Error(err);
        }

    }

    async function postConsultarBacemSimulacao(token: string) {

        try {
            const res: postConsultarBacemSuccess = await postConsultarBacem(token);
            if (res.data.status && res.data.status === "Reprovado Boa Vista") {
                return history.push('/reprovado');
            }
            if (res.data.error) {
                const erroAtual = res.data.error;
                switch (erroAtual) {

                    case "Analise de credito não aprovada":
                        if (res.data.status) {

                            if (res.data.status === "Reprovado Celular") {
                                history.push('/cnh');
                                //alterado fluxo de cnh para reprovado celular
                            } else {
                                history.push('/reprovado');
                            }
                        } else {
                            history.push('/reprovado');
                        }
                        break;
                    case "Cliente tem mais de 75 anos de idade.":
                        history.push('/reprovadoIdade');
                        break;
                    default:
                        toast.error('erro ao efetuar simulação');
                        break;
                }
            } else {
                await actionPostConsultarSpc(token);
            }

        } catch (err: any) {
            history.push('/simulacao/erro');
            toast.error('erro ao fazer simulação');
            return err
        }
    }

    async function postConsultarSpcCnh(token: string, temCnh: boolean) {

        if (!temCnh) {
            history.push('/reprovado');
            return
        }
        try {

            //const telefoneValidado = telefone.split(/\.-_|\(|\)/).join('');

            postRegistrarSimulacao(cpf, email, telefone, aceitaLead, temCnh);
            actionPostConsultarSpc(token)
        } catch (err) {
            history.push('/simulacao/erro');
            console.log(err);

        }


    }

    async function postRegistrarCnh(temCnh: boolean, token: string) {

        if (!temCnh) {
            try {
                await registrarCnhPost(token, temCnh);
                return history.push('/reprovado');
            } catch (error: any) {
                console.log(error);
                throw new Error(error);
            }
        }

        try {

            //const telefoneValidado = telefone.split(/\.-_|/).join('');
            setFluxo('cnh');
            postRegistrarSimulacao(cpf, email, telefone, aceitaLead, temCnh);
            await registrarCnhPost(token, temCnh);
            history.push('/senha');
        } catch (error: any) {
            throw new Error(error);

        }
    }

    async function actionPostConsultarSpc(token: string) {

        try {
            const res: postConsultarSpcSuccess = await postConsultarSpc(token);
            if (res.data.status && res.data.status === "Reprovado Boa Vista") {
                return history.push('/reprovado');
            }
            if (res.data.error) {
                const erroAtual = res.data.error;
                switch (erroAtual) {

                    case "Analise de credito não aprovada":
                        history.push('/reprovado');
                        break;
                    case "Cliente tem mais de 75 anos de idade":
                        history.push('/reprovadoIdade');
                        break;
                    default:
                        history.push('/');
                        toast.error('erro ao efetuar simulação, tente novamente');
                        break;
                }
            } else {

                handleSetValorEmprestimo(res.data.valor_maximo_proxima_fase!);
                history.push('/aprovado');
            }
        } catch (err: any) {
            toast.error('erro ao consultar CNH');
            return err;
        }
    }

    async function postCriarSenhaSenha(token: string, senha: string, senhaConfirmar: string, nome: string, email: string) {

        try {
            const res: postRegistrarSenhaSuccess = await postRegistrarSenha(token, senha, senhaConfirmar, nome, email);

            if (res.status === 200) {
                if (fluxo === 'cnh') {
                    return history.push('/finalizar-simulacao');
                } else {
                    return history.push('/download');
                }

            }

        } catch (err: any) {

            toast.error('erro ao criar a senha, tente novamente');
            return err
        }
    }

    async function postAlterarSenhaAlterar(token: string, senha: string, senhaConfirmar: string) {

        try {
            const res: postAlterarSenhaSuccess = await postAlterarSenha(token, senha, senhaConfirmar);

            if (res.status === 200) {

                history.push('/sucesso-senha');

            }
        } catch (err: any) {
            toast.error('erro ao tentar alterar a senha');
            return err
        }
    }

    return <UserContext.Provider value={
        {
            submitEventAnalitycs,
            aceitaLead,
            handleSetAceitaLead,
            chkCNH,
            postRegistrarCnh,
            handleSetChkCNH,
            clearAllData,
            handleSetTelefone,
            postAlterarSenhaAlterar,
            handleSetSenhaConfirmar,
            handleSetCpf,
            postRegistrarSimulacaoSimulacao,
            postConsultarSpcCnh,
            handleSetSenha,
            postCpfHome,
            handleSetEmail,
            postCriarSenhaSenha,
            cpf,
            nome,
            email,
            telefone,
            valorEmprestimo,
            senhaConfirmar,
            senha,
            token
        }
    }>
        {children}
    </UserContext.Provider>
}


export const UserContext = createContext<UserContextData>({} as UserContextData);

export function useUser() {
    const context = useContext(UserContext);

    return context;
}