import styled from "styled-components"


const Star = styled.div`
    height: 70px;
    width: 70px;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url('/assets/stars.png');
`
export function StarsImg() {

    return (
        <Star />
    )
}