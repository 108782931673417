
import { BackgroundSquares } from '../../components/BackgroundSquares/BackgroundSquares'
import { CustomButton } from '../../components/CustomButton/CustomButton'
import { Header } from '../../components/Header/Header'
import { ReprovadoImg } from '../../components/ReprovadoImg/ReprovadoImg'
import { Subheader } from '../../components/Subheader/Subheader'
import { Container } from './styles'

export function Reprovado() {

    function onSubmit() {

        window.location.href = 'http://www.monefy.com.br';
    }


    return (
        <Container>
            <Header />
            <BackgroundSquares />

            <Subheader hiddenGoBack={true} />
            <div className='subcontainer'>
                <div className='box1'>
                    <div className='box1content'>
                        <div className='infoText'>
                            <h2>Que pena!</h2>
                            <p>Infelizmente a sua solicitação foi recusada e no momento não podemos atendê-lo. Mas não fique triste, você pode tentar novamente em 30 dias.</p>
                        </div>
                        <ReprovadoImg />
                    </div>
                    <div>
                        <CustomButton onclick={onSubmit} classname='btn' content={'Voltar à tela inicial'}></CustomButton>
                    </div>
                </div>
            </div>
        </Container>
    )
}