import axios from "axios";


export function postCpf(cpf: string) {
    const url = `https://saf.monefy.com.br/api/consulta/cpf`;
    let bodyFomrData = new FormData();
    bodyFomrData.append('cpf', cpf)
    return axios({
        method: 'post',
        url,
        data: bodyFomrData
    })
}

export function postRegistrarSimulacao(cpf: string, email: string, celular: string, aceitaLead: boolean, cnh = false) {
    const url = `https://saf.monefy.com.br/api/registrar_simulacao`;

    let data = {
        'cpf': cpf,
        'email': email,
        'celular': celular,
        'aceita_lead': aceitaLead,
        'cnh': cnh,
        'web': true
    }
    return axios({
        method: 'post',
        url,
        data: data
    })
}

export function postConsultarBacem(token: string) {
    const url = `https://saf.monefy.com.br/api/integracao/consulta_bacem_verificacao`;
    // let bodyFomrData = new FormData();

    return axios({
        method: 'post',
        url,
        headers: {
            Authorization: `Bearer ${token}`,
        }
    })
}


export function postConsultarSpc(token: string) {
    const url = `https://saf.monefy.com.br/api/integracao/consulta_spc
    `;

    return axios({
        method: 'post',
        url,
        headers: {
            Authorization: `Bearer ${token}`,
        }
    })
}

export function postRegistrarSenha(token: string, senha: string, senhaConfirmar: string, nome: string, email: string) {
    const url = `https://saf.monefy.com.br/api/create`;
    let bodyFomrData = new FormData();
    bodyFomrData.append('password', senha);
    bodyFomrData.append('password_confirmation', senhaConfirmar);
    bodyFomrData.append('name', nome);
    bodyFomrData.append('email', email);


    return axios({
        method: 'post',
        url,
        headers: {
            Authorization: `Bearer ${token}`,
        },
        data: bodyFomrData
    })
}

export function postAlterarSenha(token: string, senha: string, senhaConfirmar: string) {
    const url = `https://saf.monefy.com.br/api/criar-senha`;
    let bodyFomrData = new FormData();
    bodyFomrData.append('password', senha);
    bodyFomrData.append('password_confirmation', senhaConfirmar);
    bodyFomrData.append('token', token);

    return axios({
        method: 'post',
        url,
        data: bodyFomrData,
    })
}

export function registrarCnhPost(token: string, temCnh: boolean) {
    const url = `https://saf.monefy.com.br/api/integracao/registrar_cnh`;

    let data = {
        'cnh': temCnh,
    }

    return axios({
        method: 'post',
        url,
        headers: {
            Authorization: `Bearer ${token}`,
        },
        data: data
    })
}

export function postDesinscreverEmails(id: string) {
    const url = `https://saf.monefy.com.br/api/desinscrever/${id}`;


    return axios({
        method: 'get',
        url,
    })
}