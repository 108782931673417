import { FormEvent, useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { BackgroundSquares } from '../../components/BackgroundSquares/BackgroundSquares'
import { CustomButton } from '../../components/CustomButton/CustomButton'
import { Header } from '../../components/Header/Header'
import { Loading } from '../../components/Loading/Loading'
import { Subheader } from '../../components/Subheader/Subheader'
import { validarSenha } from '../../helpers/validadores'
import { useUser } from '../../hooks/useUser'
import { CustomLabel } from '../../styles/CommonStyles/CustomLabelForInput'
import { Container } from './styles'

export function Senha() {

    const { senha, senhaConfirmar, token, handleSetSenhaConfirmar, handleSetSenha, postCriarSenhaSenha, email, nome } = useUser();

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(false);
    }, []);

    if (!token) {
        return <Redirect to="/" />;
    }

    async function handleSubmit(e: FormEvent) {
        e.preventDefault();
        const valida = validarSenha(senha, senhaConfirmar);

        if (valida) {
            setLoading(true);
            const res = await postCriarSenhaSenha(token, senha, senhaConfirmar, nome, email);

            if (res !== undefined) {
                setLoading(false);
            }
        }
    }

    return (
        <Container>
            <Loading isLoading={loading} />
            <Header />
            <BackgroundSquares />
            <Subheader />
            <div className='subcontainer'>
                <div className='box1'>
                    <form onSubmit={handleSubmit}>
                        <label className='form-info'>Defina uma senha para continuar</label>
                        <CustomLabel>Senha</CustomLabel>
                        <input className='customInput' onChange={(e) => handleSetSenha(e.target.value)} type="password" required name='senha' />
                        <CustomLabel>Confirmação da senha</CustomLabel>
                        <input className='customInput' onChange={(e) => handleSetSenhaConfirmar(e.target.value)} type="password" required name='confirmarSenha' />
                        <div>
                            <CustomButton classname='btn' content={'continuar'}></CustomButton>
                        </div>
                    </form>
                </div>
            </div>
        </Container>

    )
}