import styled from "styled-components"


const Img = styled.div`
    margin: 0 auto;
    height: 300px;
    width: 300px;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url('/assets/reprovado.webp'); 
`
export function ReprovadoImg() {

    return (
        <Img />
    )
}