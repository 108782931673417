import { FormEvent, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { BackgroundSquares } from "../../components/BackgroundSquares/BackgroundSquares";
import { CustomButton } from "../../components/CustomButton/CustomButton";
import { Header } from "../../components/Header/Header";
import { Loading } from "../../components/Loading/Loading";
import { StarsImg } from "../../components/StarsImg/StarsImg";
import { Subheader } from "../../components/Subheader/Subheader";
import { useUser } from "../../hooks/useUser";
import { Container } from "./styles";


export function CNH() {

    const { nome, token, postRegistrarCnh, handleSetChkCNH, chkCNH } = useUser();
    //const [chkCNH, setChkCNH] = useState(true);                   
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(false);

    }, []);

    if (!token) {
        return <Redirect to="/" />;
    }

    function handleChk() {
        handleSetChkCNH();
    }

    async function handleSubmit(e: FormEvent) {
        e.preventDefault();
        try {
            setLoading(true);
            await postRegistrarCnh(chkCNH, token);

        } catch (err) {
            setLoading(false);
        }

        // if (res !== undefined) {
        //     setLoading(false);
        // }

    }
    return (
        <Container>
            <Loading isLoading={loading} />
            <Header />
            <BackgroundSquares />
            <Subheader />
            <div className="subcontainer">
                <div className="box1">
                    <div className="box1content">
                        <div className="box1content-text">
                            <h2>{nome}!</h2>
                            <p>Você possui carteira de habilitação (CNH) válida ?</p>
                        </div>
                        <StarsImg />
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="form-checkbox">
                            <input
                                type="checkbox"
                                onChange={handleChk}
                                checked={chkCNH} />
                            <span>SIM</span>
                        </div>

                        <div className="form-checkbox">
                            <input type="checkbox" onChange={handleChk} checked={!chkCNH} />
                            <span>NÃO</span>
                        </div>
                        <div className="cnhImgWrapper">
                            <div className="cnhImg" />

                        </div>
                        <div className="form-btn">
                            <CustomButton content="continuar" classname="btn" />
                        </div>
                    </form>
                </div>

            </div>
        </Container>
    )
}