import { BackgroundSquares } from '../../components/BackgroundSquares/BackgroundSquares'
import { BtnDownloadApp } from '../../components/BtnDownloadApp/BtnDownloadApp'
import { CustomButton } from '../../components/CustomButton/CustomButton'
import { Header } from '../../components/Header/Header'
import { StarsImg } from '../../components/StarsImg/StarsImg'
import { Subheader } from '../../components/Subheader/Subheader'
import { useUser } from '../../hooks/useUser'
import { Container } from './styles'

export function EncaminharApp() {

    const { nome } = useUser();

    function onSubmit() {

        window.location.href = 'http://www.monefy.com.br';
    }

    return (
        <Container>
            <Header />
            <BackgroundSquares />
            <Subheader hiddenGoBack={true} />
            <div className='subcontainer'>
                <div className='box1'>
                    <div className='box1content'>
                        <div className='infoText'>
                            <h2>{nome}</h2>
                            <p>Para finalizar sua simulação, baixe nosso aplicativo e entre com seu CPF e senha.</p>
                        </div>
                        <StarsImg />
                    </div>
                    <div className='btnDownloadAppWrapper'>
                        <BtnDownloadApp />
                    </div>
                    <form>
                        <CustomButton onclick={onSubmit} classname='btn' content={'Voltar à tela inicial'}></CustomButton>
                    </form>
                </div>
            </div>
        </Container>
    )
}