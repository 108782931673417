import styled from "styled-components";

export const Container = styled.header`
    width: 100%;
    background: var(--linearGradientColor2);
    

    div{
        max-width: 1500px;
        margin: 0 auto;
        padding: 1.5rem;
        img{
            width: 200px;
            cursor: pointer;
        }
    }
`