import { FormEvent, useEffect, useState } from "react";
import { Container } from "./styles";

import InputMask from 'react-input-mask'
import { useHistory } from "react-router-dom";

import { useUser } from "../../hooks/useUser";

import { validaInputCpf } from '../../helpers/validadores'
import { CustomButton } from "../../components/CustomButton/CustomButton";
import { Loading } from "../../components/Loading/Loading";
import { Subheader } from "../../components/Subheader/Subheader";
import { BackgroundSquares } from "../../components/BackgroundSquares/BackgroundSquares";
import { Header } from "../../components/Header/Header";

export function Home() {

    const { handleSetCpf, cpf, postCpfHome, clearAllData } = useUser();
    const [loading, setLoading] = useState(false);
    const [haveCpfParam, setHaveCpfParam] = useState(false);
    const history = useHistory();

    useEffect(() => {
        setLoading(false);
        clearAllData();

        (async () => {
            await getCpfParamUrl();
        })()

    }, []);

    async function getCpfParamUrl() {
        const search = history.location.search;
        const params = new URLSearchParams(search);
        const cpfParam = params.get('cpf');

        if (cpfParam) {
            handleSetCpf(cpfParam)
            await postCpf(cpfParam);
            return
        }
        setHaveCpfParam(true);
    }

    async function handleOnSubmit(e: FormEvent) {

        e.preventDefault();
        await postCpf(cpf);

    }

    async function postCpf(cpf: string) {
        const valido = validaInputCpf(cpf);

        if (valido) {
            setLoading(true);
            const res = await postCpfHome(cpf);

            if (res !== undefined) {
                setLoading(false);
            }
        }
    }

    if (haveCpfParam) {
        return (
            <>
                <Loading isLoading={loading} />
                <Container>
                    <Header />
                    <BackgroundSquares />
                    <Subheader hiddenGoBack />
                    <div className="imgSquare" />
                    <div className="subcontainer">

                        <main>

                            <div className="box2">
                                <div className="box1contentWrapper">
                                    <div className="box1contentWrapper-text">
                                        <h2>Bem vindo (a)</h2>
                                        <p>Faça sua simulação em segundos:</p>
                                    </div>
                                    <div className="box1contentWrapper-img" />
                                </div>
                                <form onSubmit={handleOnSubmit}>

                                    <div className="inputCpf">
                                        <span className="span-inputCpf">Qual o seu CPF:</span>
                                        <InputMask required minLength={11} onChange={(e) => handleSetCpf(e.target.value)} value={cpf} className="customInput" type="text" name="cpf" id="cpf" alwaysShowMask={false} mask={'999.999.999-99'} />
                                    </div>
                                    <div className="btnWrapper">
                                        <CustomButton alternativeGradient={true} classname={"btn"} content={'Continuar'} />
                                    </div>

                                </form>
                            </div>
                        </main>
                    </div>
                </Container>
            </>
        )
    }

    return (
        <>
            <div></div>
        </>
    )

}