import styled from "styled-components"


const Container = styled.div`
    
    width: 100%;
    height: calc(100% - 10.375rem);
    position: absolute;
    z-index: -1;
    display: flex;
    justify-content: space-between;
    margin-top: 50px;

    .squareLeft{
        background-image: url('/assets/squaresLeft.png');
        background-repeat: no-repeat;
        background-size: contain;
        height: 100%;
        width: 300px;
        background-position:left;
    }
    .squareRight{
        background-image: url('/assets/squaresRight.png');
        background-repeat: no-repeat;
        background-size: contain;
        height: 100%;
        width: 300px;
        background-position:right;
    }

    @media (max-width: 720px){
        display: none;
    }

`;

export function BackgroundSquares() {

    return (
        <Container>
            <div className="squareLeft" />
            <div className="squareRight" />
        </Container>
    )

}