import { useEffect, useState } from "react"
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import { Header } from "../../components/Header/Header"
import { postDesinscreverEmails } from "../../services/services";
import { Container } from "./styles"

interface Params {
    id?: string,
    nome?: string
}

export function Desinscrever() {

    const [nomeCliente, setNomeCliente] = useState('');

    const { id, nome } = useParams<Params>();

    useEffect(() => {
        getDados();
    }, [])

    async function getDados() {
        console.log(id, nome);

        setNomeCliente(nome!);
        try {
            const response = await postDesinscreverEmails(id!);
            console.log(response.data);

        }
        catch (err) {
            console.log(err);
        }

    }
    return (
        <Container>
            <Header />
            <main>
                <h2>Olá {nomeCliente}!</h2>
                <h3>Você foi removido(a) com sucesso da lista de envio de e-mails promocionais!</h3>
            </main>
        </Container>
    )
}