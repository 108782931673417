import styled from "styled-components";


export const Container = styled.section`

    .subcontainer{
        max-width: 720px;

        margin: 0 auto;
        margin-bottom: 40px;
        padding: 1rem;

        display: flex;
        .box1{
            width: 100%;
            display: flex;
            form{
                display: flex;
                flex-direction:column;

                width: 100%;
                input + label{
                    margin-top:30px;
                }
                .form-info{
                    color:var(--blue-300);
                    
                    padding-left: 3rem;
                    margin-top: 2rem;
                    margin-bottom: 5rem;
                }
                div{
                    margin-top:40px;
                    .btn{
                        width: 50%;
                    }
                }
            }
        }
    }
`