import { Container } from './styles'


export function Header() {

    function handleOnClick() {
        window.location.href = 'http://www.monefy.com.br';
    }
    return (
        <Container>
            <div>
                <img src="/assets/MonefyLogo.png" alt='Monefy' onClick={handleOnClick} />
            </div>
        </Container>
    )
}