

import { CustomButton } from "../../components/CustomButton/CustomButton";
import { Header } from "../../components/Header/Header";
import { Subheader } from "../../components/Subheader/Subheader";
import { CustomLabel } from "../../styles/CommonStyles/CustomLabelForInput";
import { Container } from "../simulacao/styles";
import InputMask from 'react-input-mask'
import { FormEvent, useEffect, useState } from "react";
import { useUser } from "../../hooks/useUser";
import { toast } from "react-toastify";
import { validaInputCpf, validarTelefone } from "../../helpers/validadores";
import { Loading } from "../../components/Loading/Loading";
import { BackgroundSquares } from "../../components/BackgroundSquares/BackgroundSquares";

let link1 = 'https://antecipe.com/wp-content/uploads/2020/02/Termos-de-Uso.pdf';
let link2 = 'https://antecipe.com/wp-content/uploads/2020/02/Poli%CC%81ticas-de-Privacidade.pdf';


export function Simulacao() {

    const { cpf, email, telefone, handleSetCpf, handleSetEmail, handleSetTelefone, postRegistrarSimulacaoSimulacao, aceitaLead, handleSetAceitaLead } = useUser();
    const [chkTermos, setChkTermos] = useState(false);


    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(false);
    }, []);

    function toggleChkTermos() {
        setChkTermos(!chkTermos);
    }
    function toggleChkEmails() {
        handleSetAceitaLead();
    }

    async function handleSubmit(e: FormEvent) {
        e.preventDefault();
        const telefoneValido = validarTelefone(telefone);
        const cpfValido = validaInputCpf(cpf);

        if (!chkTermos) {
            toast.error('É preciso aceitar os termos para continuar');
        } else {
            if (telefoneValido && cpfValido) {
                try {
                    setLoading(true);
                    await postRegistrarSimulacaoSimulacao(cpf, email, telefone, aceitaLead);
                } catch (err) {
                    setLoading(false);
                }

            }
        }
    }

    return (
        <Container>
            <Loading isLoading={loading} />
            <Header />
            <BackgroundSquares />
            <Subheader hiddenGoBack />
            <div className="subcontainer">
                <div className="box1">
                    <div className="box1contentWrapper">
                        <div className="box1contentWrapper-text">
                            <h2>Bem vindo (a)</h2>
                            <p>Faça sua simulação em segundos:</p>
                        </div>
                        <div className="box1contentWrapper-img" />
                    </div>
                </div>
                <div className="box2">
                    <form onSubmit={handleSubmit}>
                        <CustomLabel>QUAL O SEU CPF:</CustomLabel >
                        <InputMask required className="customInput" alwaysShowMask={false} onChange={(e) => handleSetCpf(e.target.value)} defaultValue={cpf} mask={'999.999.999-99'} />

                        <CustomLabel>QUAL O SEU E-MAIL:</CustomLabel >
                        <InputMask required type={'email'} onChange={(e) => handleSetEmail(e.target.value)} className="customInput" alwaysShowMask={false} mask={''} />

                        <CustomLabel>QUAL O NÚMERO DO SEU CELULAR:</CustomLabel >
                        <InputMask required type={'tel'} onChange={(e) => handleSetTelefone(e.target.value)} className="customInput" alwaysShowMask={false} mask={'(99) 9 9999-9999'} />

                        <div className="formSimulacao-checkbox">
                            <input type="checkbox" checked={chkTermos} onChange={toggleChkTermos} />
                            <span>Li e concordo com os <a href={link1}>termos de uso</a>, e a <a href={link2}>política de privacidade</a>.</span>
                        </div>

                        <div className="formSimulacao-checkbox">
                            <input type="checkbox" checked={aceitaLead} onChange={toggleChkEmails} />
                            <span>Aceito receber os conteúdos da Monefy e compreendo que posso me descadastrar a qualquer momento.</span>
                        </div>
                        <div className="formSimulacao-btn">
                            <CustomButton content="continuar" classname="btn" />
                        </div>
                    </form>
                </div>
            </div>
        </Container >
    )
}




