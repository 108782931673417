
import { BackgroundSquares } from '../../components/BackgroundSquares/BackgroundSquares';
import { CustomButton } from '../../components/CustomButton/CustomButton';
import { Header } from '../../components/Header/Header';
import { ReprovadoImg } from '../../components/ReprovadoImg/ReprovadoImg';
import { Subheader } from '../../components/Subheader/Subheader';
import { Container } from './styles';
import ConsultaImg from '../../assets/LoadingCNH.gif';

export function ErroSimulacao() {

    function onSubmit() {
        window.location.href = 'http://www.monefy.com.br';
    }

    return (
        <Container>
            <Header />
            <BackgroundSquares />
            <Subheader hiddenGoBack={true} />
            <div className='subcontainer'>
                <div className='box1'>
                    <div className='box1content'>
                        <div className='infoText'>
                            <h2>OPS!</h2>
                            <p>Estamos com uma instabilidade no momento, retorne em alguns minutos.</p>
                        </div>
                        <img src={ConsultaImg} />
                    </div>
                    <div>
                        <CustomButton onclick={onSubmit} classname='btn' content={'Voltar à tela inicial'}></CustomButton>
                    </div>
                </div>
            </div>
        </Container>
    )
}