import styled from "styled-components";

export const Container = styled.section`

    .subcontainer{
        max-width: 720px;
        margin: 0 auto;
        padding: 1rem;
        display: flex;
        gap: 1rem;

        .box1{
            width: 100%;
           
            .box1content{
                display: flex;
                justify-content:space-between;

                .box1content-text{
                    h2{
                        color: var(--blue-350);
                        font-size: 2rem;
                      
                    }
                    p{
                        margin-top:15px;
                        color: var(--blue-300);
                    }
                }
            }
            form{
                text-align: center;
                margin-top: 40px;
                .form-checkbox{
                    width: 50%;
                    display: inline-block;
                    margin-top: 0px;
                    input{
                        position: relative;
                        top: 1px;
                        margin-right: 5px;
                    }
                    span{
                        color: var(--blue-350);
                    }
                }

                .form-btn{
                    width: 100%;
                    margin-top: 40px;
                    .btn{
                        width: 70%;
                    }
                }
            }
        }

        .cnhImgWrapper{
            width: 100%;
            margin: 30px 0;
            .cnhImg{
                margin: 0 auto;
                width: 350px;
                height: 250px;
                max-width: 100%;
                background-image: url('/assets/cnhImg.webp');
                background-size: contain;
                background-repeat: no-repeat;
            }
        }
    }
`