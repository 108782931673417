import styled from "styled-components"

// interface props {
//     props: {
//         color: string;
//     };
// }

export const CustomBtn = styled.div.attrs((props: any) => ({

    color: props.color
}))`

    height: 3rem;
    position: relative;
    text-align: center;
    display: flex;
    justify-content: center;

    .btn1{
    background: ${props => props.color};
    height: 2.6rem;
    border-radius: 20px;
    border: none;
    z-index: 2;
    position: absolute;
    text-transform: uppercase;
    color: var(--white-100);
    top: 0;

        &:active{
            
            top:1px
        }
    }

    .btn2{
    bottom: 0;
    position: absolute;
    z-index: 1;
    border-radius: 20px;
    border: 1px solid var(--blue-300);
    background: transparent;
    height: 2.6rem;
    }
    
    
`