import { Redirect, useHistory } from 'react-router-dom'
import { BackgroundSquares } from '../../components/BackgroundSquares/BackgroundSquares'
import { CustomButton } from '../../components/CustomButton/CustomButton'
import { Header } from '../../components/Header/Header'
import { Subheader } from '../../components/Subheader/Subheader'
import { formatPrice } from '../../helpers/format'
import { useUser } from '../../hooks/useUser'
import { Container } from './styles'

export function Aprovado() {

    const { valorEmprestimo, token, submitEventAnalitycs } = useUser();
    const history = useHistory();
    const valorFormatado = formatPrice(parseInt(valorEmprestimo));

    submitEventAnalitycs("Aprovado_web");

    if (!token) {
        return <Redirect to="/" />;
    }
    function handleSubmit() {
        history.push('/senha');
    }

    return (
        <Container>
            <Header />
            <BackgroundSquares />
            <Subheader />
            <div className='subcontainer'>
                <div className='box1'>
                    <div className='box1content'>
                        <div className='box1content-text'>
                            <h2>Parabéns!</h2>
                            <p>Você tem:</p>
                        </div>
                        <div className='box1content-img' />
                    </div>
                    <div className='loanValue'>
                        <span>{valorFormatado}</span>
                        <p>disponível</p>
                    </div>
                    <p className='box1-infoText'>Em empréstimo para pagamento em 8 vezes</p>
                    <div className='imgWrapper'>
                        <div className='img' />
                    </div>
                    <div>
                        <CustomButton onclick={handleSubmit} classname='btn' content={'solicitar empréstimo'}></CustomButton>
                    </div>
                </div>
            </div>
        </Container>
    )
}