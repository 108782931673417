import styled from "styled-components"

const SpanLocation = styled.div`
    font-size: 2rem;
    color: var(--blue-350);
    font-weight: bold;
    margin-top: 50px;
    
`

interface LocationProps {
    text: string
}

export function Location({ text }: LocationProps) {
    return (
        <SpanLocation>{text}</SpanLocation>
    )
}