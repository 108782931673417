import styled from "styled-components";

export const Container = styled.section`

    .subcontainer{
        max-width: 720px;
        margin: 0 auto;
        padding: 1rem;
        display: flex;
        gap: 1rem;

        .box1{
            width: 100%;
            display: flex;
            flex-direction:column;

            .box1content{
                display: flex;
                margin-bottom: 50px;
                flex-direction: column;
                .infoText{
                    
                    h2{
                        color: var(--blue-350);
                        font-size: 2.5rem;
                        margin-bottom: 10px;
                    }
                    p{
                        text-align: justify;
                        color: var(--blue-300);
                        margin-bottom: 30px;
                    }
                }
                img{
                    width: 300px;
                    margin: 0 auto;
                }
            }
            div{
                .btn{
                    width: 50%;
                }
            }
        }
       
    }

    @media (max-width: 850px){

        .subcontainer{
          
            .box1{
                
                padding: 0;
                .box1content{
                    .infoText{
                        width:100%;
                    }
                }
               
            }
        }
    }
`